import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap";
import logo from "../img/main_logo_white.png";

const Navigation = () => {
  const hoveringEffectOn = (e) => {
    //using the e.target to change the innerHTML of the hovered element
    let Changetext = e.target.innerHTML;
    let ChangetextTo = Changetext.replace(
      e.target.innerHTML,
      `[${e.target.innerHTML}]`
    );
    e.target.innerHTML = ChangetextTo;
    e.target.style.color = "cyan";
  };

  const hoveringEffectOff = (e) => {
    let ChangeBacktext = e.target.innerHTML;
    let ChangeBacktextTo = ChangeBacktext.replace(
      e.target.innerHTML,
      e.target.innerHTML.slice(1, -1)
    );
    e.target.innerHTML = ChangeBacktextTo;
    e.target.style.color = "whitesmoke";
  };

  return (
    <Navbar expand="lg">
      <Navbar.Brand href="/">
        <img src={logo} alt="Netglitch Logo" className="ms-2 logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link
            href="/"
            id="home"
            onMouseOver={hoveringEffectOn}
            onMouseOut={hoveringEffectOff}
          >
            Home
          </Nav.Link>
          <Nav.Link
            href="/servers"
            id="servers"
            onMouseOver={hoveringEffectOn}
            onMouseOut={hoveringEffectOff}
          >
            Server Software
          </Nav.Link>
          <Nav.Link
            href="/serverslist"
            id="serversList"
            onMouseOver={hoveringEffectOn}
            onMouseOut={hoveringEffectOff}
          >
            Server List
          </Nav.Link>
          <Nav.Link
            href="/users"
            id="users"
            onMouseOver={hoveringEffectOn}
            onMouseOut={hoveringEffectOff}
          >
            Users
          </Nav.Link>
          <Nav.Link
            href="/aboutus"
            id="aboutUs"
            onMouseOver={hoveringEffectOn}
            onMouseOut={hoveringEffectOff}
          >
            About Us
          </Nav.Link>
          <Nav.Link
            href="/contactus"
            id="contactUs"
            onMouseOver={hoveringEffectOn}
            onMouseOut={hoveringEffectOff}
          >
            Contact Us
          </Nav.Link>
        </Nav>
        <Form className="d-flex">
          <FormControl
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
          />
          <Button variant="outline-white" className="me-3">
            Search
          </Button>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
