import { Accordion } from "react-bootstrap";

const TermsOfService = () => {
  return (
    <>
      <Accordion.Body>
        Nothing to see here yet sorry this is just some sample text
      </Accordion.Body>
    </>
  );
};

export default TermsOfService;
