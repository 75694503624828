import { Accordion } from "react-bootstrap";
import { useEffect } from "react";
import Info from "./PageData/AboutUs/Info";
import TermsOfUse from "./PageData/AboutUs/TermsOfUse";
import TermsOfService from "./PageData/AboutUs/TermsOfService";

const AboutUs = () => {
  useEffect(() => {
    document.querySelector("#aboutUs").style.color = "cyan";
  });
  return (
    <section className="mt-5 ms-lg-5 DropdownAccordion">
      <Accordion alwaysOpen flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Infomation on NetGlitch</Accordion.Header>
          <Info />
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="mt-3">
          <Accordion.Header>Terms of Service</Accordion.Header>
          <TermsOfService />
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="mt-3">
          <Accordion.Header>Terms of Use</Accordion.Header>
          <TermsOfUse />
        </Accordion.Item>
      </Accordion>
    </section>
  );
};

export default AboutUs;
