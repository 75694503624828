import { Fragment, useEffect } from "react";
import DeathgardenTab from "./PageData/ServerSoftware/Deathgarden/DeathgardenTab";
import SubnaticaTab from "./PageData/ServerSoftware/Subnatica1&2/SubnaticaTab";
import SubnaticaBelowZeroTab from "./PageData/ServerSoftware/Subnatica1&2/SubnaticaBelowZeroTab";

const Servers = () => {
  useEffect(() => {
    document.querySelector("#servers").style.color = "cyan";
  });
  return (
    <Fragment>
      <article className="row mb-5">
        <DeathgardenTab />
        <SubnaticaTab />
        <SubnaticaBelowZeroTab />
      </article>
    </Fragment>
  );
};

export default Servers;
