export const News1 = () => {
  return (
    <div className="ms-lg-5">
      <h1>Welcome to 1.0</h1>
      <p className="mt-2 ms-lg-3">
        Welcome to NetGlitch&copy; launch. <br /> We are happy to launch version
        1 of NetGlitch&copy; If you find any page issues or bugs please report
        them to us. As our webpage is very new you may experience some bugs or
        missing content but don't worry, over time everything will be fixed
        hopefully &#128512;. <br /> We will be using the homepage as a forum
        update so please remember to check it for updates and important
        announcement
      </p>
    </div>
  );
};
