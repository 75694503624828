import { Accordion } from "react-bootstrap";

const Info = () => {
  return (
    <>
      <Accordion.Body>
        <h1>Who we are</h1>
        <p>
          We are a group of dedicated people who try and make server software
          for games that are either dead or games that have no multiplayer of
          any kind. It started off with the owner Scott Zangenberg known as
          LonlyGamerX trying to bring back a dead game called Deathgarden which
          was the first game we started off and he had the idea to bring server
          software for other games where there was none but could be very fun to
          have like Subnatica Below Zero
        </p>
        <h1>Goals</h1>
        <p>
          Our goal is to make server software for all kinds of games where there
          is none. We do not currently have a list of all the games we plan on
          making multiplayer for. So far we mainly just do a list with 3 games
          we vote on and try and do them first and work our way though all the
          games. Even if a game is dead does not mean we won't try and make
          multiplayer for it. We understand that games can die due to various of
          reasons and that is sad and depressing but, we want to make software
          so incase people who might want to go on a nostigla run with some
          friends can do that or incase some dedicated fans wants to keep
          playing the game can.
        </p>
        <h1>Where do i sign up to join?</h1>
        <p>
          Go to Career at the top and enter your info. If the Career is not
          visible at the top then it means we aren't currently looking for
          people. Just keep looking at the home page for updates as we will post
          there if we are looking for people to join us.
        </p>
      </Accordion.Body>
    </>
  );
};

export default Info;
//Spell check it