import { Link } from "react-router-dom";
import SubnaticaImg from "../../../../img/Subnatica.jpg";

const SubnaticaTab = () => {
  return (
    <>
      <section className="ServerBox ms-lg-5 col-lg-3">
        <img
          src={SubnaticaImg}
          alt="Deathgarden icon"
          className="SubnaticaImg ServerBoxImg mb-4 mt-lg-2"
        />
        <h2>Subnatica</h2>
        <div className="d-flex flex-column">
          <Link
            to="/files/[Game].txt"
            target="_blank"
            className="mb-3 d-flex flex-column"
            download={true}
          >
            <button>Download</button>
          </Link>
          <Link to="/servers/subnatica" className="mb-3 d-flex flex-column">
            <button>More Info</button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default SubnaticaTab;
