export const News2 = () => {
  return (
    <div className="ms-lg-5">
      <h1>Servers list unavailable</h1>
      <p className="mt-2 ms-lg-3">
        Currently the server list is unavailable due to api issues. This will
        be fixed later on. Currently we only have Minecraft servers as we
        don't not have any other servers available but we will have Deathgarden
        servers up soon!
      </p>
    </div>
  );
};
