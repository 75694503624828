import { useEffect } from "react"

const Users = () => {
  useEffect(() => {
    document.querySelector("#users").style.color = "cyan";
  });
  return (
    <div>Users</div>
  )
}

export default Users