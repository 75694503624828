const Footer = () => {
  return (
    <section className="footer mt-5">
      <div className="row">
        <div className="col-md-12 ">
          <p>
            Copyright &copy; 2022 NetGlitch Registered in the Denmark. Company
            Number XXXX. All rights reserved.
          </p>
        </div>
      </div>
    </section>
  )
};

export default Footer;
