import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navigation from "./components/Navigation";
import Home from "./pages/Home";
import Servers from "./pages/Servers";
import ServerList from "./pages/ServerList";
import Users from "./pages/Users";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Footer from "./components/Footer";
import Ascii from "./components/Ascii";

//Server software
import DeathgardenPage from "./pages/PageData/ServerSoftware/Deathgarden/DeathgardenPage";
import SubnaticaPage from "./pages/PageData/ServerSoftware/Subnatica1&2/SubnaticaPage";
import SubnaticaBelowZeroPage from "./pages/PageData/ServerSoftware/Subnatica1&2/SubnaticaBelowZeroPage";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Router>
        <section>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/servers" element={<Servers />} />
            <Route path="/serversList" element={<ServerList />} />
            <Route path="/users" element={<Users />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/contactUs" element={<ContactUs />} />
            {/* Server software routes */}
            <Route path="/servers/deathgarden" element={<DeathgardenPage />} />
            <Route path="/servers/subnatica" element={<SubnaticaPage />} />
            <Route
              path="/servers/subnaticaBelowZero"
              element={<SubnaticaBelowZeroPage />}
            />
          </Routes>
        </section>
      </Router>
      <Footer />
      <Ascii />
    </div>
  );
}

export default App;
