import { useEffect } from "react";

const ServerList = () => {
  useEffect(() => {
    document.querySelector("#serversList").style.color = "cyan";
  });
  
  return (
    <div>ServerList</div>
  )
}

export default ServerList