import { useEffect } from "react";
import { News1 } from "./PageData/Home/News1";
import { News2 } from "./PageData/Home/News2";

const Home = () => {
  useEffect(() => {
    document.querySelector("#home").style.color = "cyan";
  });

  return (
    <section className="mt-3 mb-3">
      <section className="col-lg-8 ms-lg-5 newsBubble">
        <News2 />
      </section>
      <section className="col-lg-8 ms-lg-5 newsBubble">
        <News1 />
      </section>
    </section>
  );
};

export default Home;

//Just Copy and paste this below but change news1 to a newer number and paste it below the toppest section with the class mt-3
//      <section className="col-lg-8 ms-lg-5 newsBubble">
//        <News1 />
//      </section>
