//Using this to print the ascii art in console
import figlet from 'figlet';
import big from 'figlet/importable-fonts/Big.js'

figlet.parseFont('Big', big);

figlet.text('NetGlitch', {
    font: 'Big'
}, function(err, data) {
    console.log(data);
});

const Ascii = () => {
  return (
    <></>
  )
}

export default Ascii