import { useEffect } from "react"

const ContactUs = () => {
  useEffect(() => {
    document.querySelector("#contactUs").style.color = "cyan";
  });
  return (
    <div>ContactUs</div>
  )
}

export default ContactUs